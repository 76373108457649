<template>
  <div class="w-100 h-100 d-flex justify-content-center align-items-center">
    <div v-if="preMeeting">
      <div v-if="preMeeting.type === 'call'">
        <div v-if="call.status === 'calling'" class="d-flex justify-content-center flex-column align-items-center">
          <div class="calling pulsating d-flex justify-content-center align-items-center">
            <i v-if="meetingOptions.startWithVideoMuted" class="bi bi-headset text-white align-self-center"></i>
            <i v-else class="bi bi-camera-video text-white align-self-center"></i>
          </div>
        </div>
        <div v-if="call.status === 'stopped'" class="w-100">
          <div class="text-center text-uppercase" style="font-weight: 600;">Try Again</div>
          <div class="text-center mb-3">It seems that all agents are busy at this moment.</div>
          <div class="text-center">
            <button class="btn btn-primary me-2" @click="placeCall('video')">
              <i class="bi bi-camera-video"></i> Video Call
            </button>
            <button class="btn btn-primary"  @click="placeCall('audio')">
              <i class="bi bi-mic"></i> Audio Call
            </button>
          </div>
        </div>

        <div v-if="call.status === 'answered' && meeting && callee" style="position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 99999; background: #000;">
          <MeetingInterface :meeting="meeting"/>
        </div>

        <div v-if="call.status === 'closed'" class="w-100">
          <div class="text-center mb-3">Thank you for using {{realm.title}}</div>
        </div>
      </div>
      <div v-else>
        <button class="btn btn-primary me-2" @click="placeCall('video')">
          <i class="bi bi-camera-video"></i> Video Call
        </button>
        <button class="btn btn-primary" @click="placeCall('audio')">
          <i class="bi bi-mic"></i> Audio Call
        </button>
      </div>
    </div>
    <div v-else>
      loading...
    </div>
  </div>
</template>
<script>
import {ref,defineAsyncComponent} from 'vue';
export default {
  components: {
    MeetingInterface: defineAsyncComponent(() => import('@/components/meet/MeetingInterface')),
  },
  setup(){
    return {
      preMeeting: ref(null),
      visitor: ref(null),
      realm: ref(null),
      call: ref({
        seconds: 0,
        maxSeconds: 30,
        callInterval: null,
        status: "pending"
      }),
      meeting: ref(null),
      meetingOptions: ref({
        startWithAudioMuted: false,
        startWithVideoMuted: true,
      }),
      meetingData: ref(null),
      callee: ref(null)
    }
  },
  mounted(){
    this.$router.isReady().then(() => {
      this.$ws.connect(this.$route.query);

      this.$ws.on('premeeting', ({preMeeting, visitor, realm}) => {
        console.log('@premeeting', {preMeeting, visitor, realm});
        this.preMeeting = preMeeting;
        this.visitor = visitor;
        this.realm = realm;

        if(preMeeting.type === "call"){
          this.initiateCall();
        }
      });

      this.$ws.on('meeting', data => {
        console.log('@meeting', data);
        this.meetingData = data;
      })

      this.$ws.on('answer', data => {
        console.log('@answer', data);
        this.callee = data;
        this.call.status = "answered";
        clearInterval(this.call.callInterval);
        this.loadMeeting();
      })
    })
  },

  methods: {
    placeCall(type){
      this.meetingOptions = {
       startWithAudioMuted: false,
       startWithVideoMuted: type === "video" ? false : true,
     };

     this.initiateCall();
    },
    initiateCall(){
      this.$ws.initiateCall();

      this.call.status = "calling";
      this.call.seconds = 0;
      this.call.callInterval = setInterval(() => {
        this.call.seconds++;
        if(this.call.seconds === this.call.maxSeconds){
          this.closeCall("stopped");
        }
      },1000)
    },
    loadMeeting(){
      this.meeting = {
        meeting: this.meetingData,
        meetingOptions: this.meetingOptions,
        on: {
          loaded: () => {
            console.log('------------------------------> meeting loaded')
          },
          readyToClose: () => {
            console.log('------------------------------> ready to close')
            this.closeCall("closed");
          }
        }
      }
    },

    closeCall(status){
      clearInterval(this.call.callInterval);
      this.call.status = status;

      this.$ws.closeCall({meeting: this.meetingData});
      this.meeting = null;
      this.meetingData = null;
    }
  },
}
</script>

export default {
  namespaced: true,
  state: {
    preMeeting: null,
    visitor: null,
    realm: null,
    callee: null,
    meetingData: null,
    notifications: []
  },
  mutations: {
    mutateInitialization(state,data){
      state.preMeeting = data === null ? null : Object.assign({}, data.preMeeting);
      state.visitor = data === null ? null : Object.assign({}, data.visitor);
      state.realm = data === null ? null : Object.assign({}, data.realm);
    },
    mutateMeetingData(state,data){
      state.meetingData = data === null ? null : Object.assign({}, data);
    },
    mutateCallee(state,data){
      state.callee = data === null ? null : Object.assign({}, data);
    },
    mutateAddNotification(state,data){
      data.id = Math.random();
      state.notifications = Object.assign([],state.notifications.concat(data));
    },
    mutateRemoveNotification(state,data){
      state.notifications = Object.assign([],state.notifications.filter(o => o.id !== data.id));
    },
  },
  getters: {
    getPreMeeting(state){
      return state.preMeeting;
    },
    getVisitor(state){
      return state.visitor;
    },
    getRealm(state){
      return state.realm;
    },
    getCallee(state){
      return state.callee;
    },
    getMeetingData(state){
      return state.meetingData;
    },
    getNotifications(state){
      return state.notifications;
    }
  },
  actions: {
    setInitialization({commit}, data){
      return commit('mutateInitialization', data);
    },
    setMeetingData({commit}, data){
      return commit('mutateMeetingData', data);
    },
    setCallee({commit}, data){
      return commit('mutateCallee', data);
    },
    addNotification({commit}, data){
      return commit('mutateAddNotification', data);
    },
    removeNotification({commit}, data){
      return commit('mutateRemoveNotification', data);
    },
  }
}

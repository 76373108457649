import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import ws from './services/ws.service';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ws);

import bootstrap from './services/bootstrap.service';
app.use(bootstrap);

import './assets/style.css';

app.mount('#app')

import io from 'socket.io-client';
import storage from './visitor.storage.service';
//import store from '@/store';
const ws = {
  _socket: null,
  socket(){
    if(!this._socket){
      this.connect();
    }

    return this._socket;
  },
  connect(tokens){
    let returningVisitor = storage.getItem('visitor');
    this._socket = io(process.env.VUE_APP_LIVE_HOST,{
      transports: ['websocket','polling'],
      secure: true,
      auth: {
        visitor: tokens,
        visitorId: returningVisitor ? returningVisitor.id : null
      }
    })

    this.registerEvents();
  },
  on(name,cb){
    return this._socket.on(name,cb);
  },
  registerEvents(){
    /*
    this._socket.on("connect",() => {
      console.log("-----------> @connect", this._socket.id)
    })
    this._socket.on("disconnect",() => {
      console.log("-----------> @disconnect", this._socket.id)
    })

    this._socket.on("visitor",(data) => {
      console.log("-----------> @visitor", data)
      storage.save(data);
      store.dispatch('Socket/setInitialization', data);
    })
    this._socket.on("answer",(data) => {
      console.log("-----------> @answer", data);
      store.dispatch('Socket/setCallee', data);
    })

    this._socket.on("meeting", data => {
      console.log("-----------> @meeting", data)
      store.dispatch('Socket/setMeetingData',data)
    })
    */
  },

  initiateCall(){
    this._socket.emit('initiate-call');
  },
  placeCall(data){
    this._socket.emit('place-call', data);
  },
  closeCall(data){
    this._socket.emit('close-call', data);
  }
}

export default {
  install: (app) => {
    app.config.globalProperties.$ws = ws;
  }
}

//import store from '@/services/store';
import storage from '@/services/storage.browser.service';
const name = 'callstr-visitor';

export default {
  get(){
    if(!storage.local.has(name)){
      return null;
    }

    return JSON.parse(storage.local.get(name));
  },
  save(data){
    storage.local.set(name, JSON.stringify(data));
    //return store.dispatch('Auth/setAuth',{user,tokens});
  },

  getItem(itemName){
    let data = this.get();
    if(!data){
      return null;
    }

    return data[itemName];
  }
}
